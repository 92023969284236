<template>
    <div class="box password">
        <h2>修改密码</h2>
        <div class="security" v-loading="loading">
            <el-form :model="passWordForm" ref="passWordRef" :rules="passWordRules">
                <el-form-item prop="oldPass" required>
                    <el-input v-model="passWordForm.oldPass" type="password" placeholder="请输入您的原密码"  show-password>
                        <template slot="prepend">
                            <img src="~img/alibaba/lock-base.png" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="pass" required>
                    <el-input v-model="passWordForm.pass" type="password" placeholder="请输入您的新密码,由8-20位字母或数字与符号组成"  show-password
                        >
                        <template slot="prepend">
                            <img src="~img/alibaba/lock-base.png" />
                        </template>
                    </el-input>
                    <el-form-item label="" class="password-strength">
                        <password-strength v-model="passWordForm.pass"></password-strength>
                    </el-form-item>
                </el-form-item>
                <el-form-item prop="checkPass" required>
                    <el-input v-model="passWordForm.checkPass" type="password" placeholder="请再次输入您的新密码"  show-password>
                        <template slot="prepend">
                            <img src="~img/alibaba/lock-base.png" />
                        </template>
                    </el-input>
                    <el-form-item label="" class="password-strength">
                        <password-strength v-model="passWordForm.checkPass"></password-strength>
                    </el-form-item>
                </el-form-item>
                <el-button @click="save">确认修改</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
import security from "./security"
import PasswordStrength from "@/components/PasswordStrength.vue"

export default {
    name: "security",
    mixins: [security],
    components: { PasswordStrength },

    data() {
        return {
            password: ""
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;
    }

    .security {
        margin-top: 30px;
    }

    .el-input {
        width: 400px;
    }

    .el-button {
        width: 400px;
        height: 40px;
        background-color: $base-color;
        color: #ffffff;
        font-size: $ns-font-size-lg;
    }
}
</style>

<style type="text/css" lang="scss">
.password {
    .el-input-group__prepend {
        padding: 0;
        width: 40px;
        box-sizing: border-box;
        text-align: center;

        img {
            width: 25px;
            height: 25px;
        }
    }
}
.password-strength {
	margin-top: 10px;
	width: 440px;
    position: absolute;
    top: 0px;
    left: 420px;
    width: 100px;
}
</style>
